import { Ref, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { createPortal } from "react-dom"
import {
  Popover as AriaPopover,
  Menu as AriaMenu,
  MenuItem as AriaMenuItem,
  Separator as AriaSeparator,
  Button as AriaButton,
} from "react-aria-components"
import { tv } from "tailwind-variants"

export type ContextMenuOption =
  | {
      type: "option"
      label: string
      onClick: () => void
    }
  | {
      type: "separator"
    }

type ContextMenuProps = {
  onOpen?: () => void
  onClose?: () => void
}

export interface ContextMenuHandle {
  open(options: ContextMenuOption[], position: { x: number; y: number }): void
  close(): void
}

const popoverStyles = tv({
  base: "min-w-52 overflow-hidden rounded border border-white/[15%] bg-zinc-900/70 bg-clip-padding text-zinc-300 shadow-2xl backdrop-blur-2xl backdrop-saturate-200",
  variants: {
    isEntering: {
      true: "duration-200 ease-out animate-in fade-in slide-in-from-left-1 slide-in-from-top-1",
    },
    isExiting: {
      true: "duration-150 ease-in animate-out fade-out slide-out-to-left-1 slide-out-to-top-1",
    },
  },
})

const menuItemStyles = tv({
  base: "group flex cursor-pointer select-none truncate rounded px-3 py-2 text-xs outline outline-0",
  variants: {
    isDisabled: {
      false: "text-zinc-100",
      true: "text-zinc-600",
    },
    isFocused: {
      true: "bg-blue-600 text-white",
    },
  },
})

export default forwardRef(function ContextMenu(
  { onOpen, onClose }: ContextMenuProps,
  ref: Ref<ContextMenuHandle>,
) {
  const [open, setOpenState] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [options, setOptions] = useState<ContextMenuOption[]>([])

  const setOpen = (state: boolean) => {
    setOpenState(state)
    if (state) {
      onOpen?.()
    } else {
      onClose?.()
    }
  }

  const triggerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const closeHandler = (e: Event) => {
      setOpen(false)
      e.preventDefault()
    }

    if (open) {
      document.addEventListener("contextmenu", closeHandler)
    }

    return () => {
      document.removeEventListener("contextmenu", closeHandler)
    }
  }, [open])

  useImperativeHandle(ref, () => ({
    open(options: ContextMenuOption[], position: { x: number; y: number }) {
      setOptions(options)
      setPosition(position)
      setTimeout(() => {
        setOpen(true)
      })
    },
    close() {
      setOpen(false)
      setOptions([])
    },
  }))

  const handleOptionClick = (option: Extract<ContextMenuOption, { type: "option" }>) => {
    option.onClick()
    setOpen(false)
  }

  if (!open) return null

  const trigger = (
    <div ref={triggerRef} className="fixed z-50" style={{ left: position.x, top: position.y }}>
      <div className="h-1 w-1 bg-transparent" />
    </div>
  )

  const menu = (
    <AriaPopover
      placement="bottom left"
      triggerRef={triggerRef}
      isOpen={open}
      onOpenChange={setOpen}
      className={popoverStyles}
    >
      <AriaMenu
        selectionMode="none"
        className="max-h-[inherit] overflow-auto p-1 outline outline-0"
        aria-label="Context Menu"
      >
        {options.map((option, i) =>
          option.type === "separator" ? (
            <AriaSeparator key={i} className="mx-3 my-1 border-b border-zinc-700" />
          ) : (
            <AriaMenuItem
              key={i}
              className={menuItemStyles}
              textValue={option.label}
              onAction={() => handleOptionClick(option)}
            >
              {option.label}
            </AriaMenuItem>
          ),
        )}
      </AriaMenu>
    </AriaPopover>
  )

  return (
    <>
      {createPortal(trigger, document.body)}
      {menu}
    </>
  )
})
