import { CellInfo, CellId, CellType } from "@/engine/state/types"
import { useEngine } from "../document/EngineContext"
import {
  Code2Icon,
  FileIcon,
  GlobeIcon,
  LucideIcon,
  NotepadTextIcon,
  SheetIcon,
} from "lucide-react"

interface EmptyCellViewProps {
  cellId: CellId
}

export default function EmptyCellView({ cellId }: EmptyCellViewProps) {
  const engine = useEngine()

  const items: CellTypeOption[] = [
    {
      title: "Table",
      description: "",
      icon: SheetIcon,
      background: "bg-pink-500",
      type: "table",
    },
    {
      title: "Script",
      description: "",
      icon: Code2Icon,
      background: "bg-yellow-500",
      type: "code",
    },
    {
      title: "Text",
      description: "",
      icon: NotepadTextIcon,
      background: "bg-green-500",
      type: "text",
    },
    {
      title: "HTTP Request",
      description: "",
      icon: GlobeIcon,
      background: "bg-blue-500",
      type: "http",
    },
    {
      title: "JSON Snippet",
      description: "",
      icon: FileIcon,
      background: "bg-indigo-500",
      type: "snippet",
      options: {
        language: "json",
      },
    },
    {
      title: "SQL Snippet",
      description: "",
      icon: FileIcon,
      background: "bg-red-500",
      type: "snippet",
      options: {
        language: "sql",
        dialect: "postgres",
      },
    },
  ]

  function onSelectCellType(type: CellInfo["type"], options: Record<string, any> | undefined) {
    engine.stateManager.setCellType(cellId, type, options)
  }

  return (
    <div className="h-full select-none overflow-y-auto px-4 py-2">
      <ul className="grid h-full grid-cols-2 gap-4">
        {items.map((item, index) => (
          <li
            key={index}
            onClick={() => onSelectCellType(item.type, item.options)}
            className="flow-root"
          >
            <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 hover:bg-gray-800">
              <div
                className={`${item.background} flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-lg`}
              >
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-200">
                  <div>
                    <span className="absolute inset-0" aria-hidden="true" />
                    <span>{item.title}</span>
                    <span aria-hidden="true"> &rarr;</span>
                  </div>
                </h3>
                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

type CellTypeOption = {
  title: string
  description: string
  icon: LucideIcon
  background: string
  type: CellType
  options?: Record<string, any>
}
